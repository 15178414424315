import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import { useUserSlice } from "@/models/useUserSlice";
import { Pressable, StyleSheet, Text, View } from "@/ui";
import DesktopContainer from "./DesktopContainer";
import Icon, { icons } from "./Icon";
import { useTranslation } from "react-i18next";
import { getLangDir, getMobileOperatingSystem } from "@/models/utils";
import { datadogLogs } from "@datadog/browser-logs";

const LandingPageHeading = ({ login }: { login: () => void }) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const user = useUserSlice((state) => state.user);
  const { t } = useTranslation();
  const isRTL = getLangDir() === "rtl";

  const device = getMobileOperatingSystem();

  const width = useUISlice((state) => state.width);

  const _onLargeButtonPress = () => {
    LandingPageHeading.signUpOrDownloadApp(login);
  };

  const forceInstall = LandingPageHeading.forceInstall;

  const renderFeature = ({
    icon,
    title,
  }: {
    icon: keyof typeof icons;
    title: string;
  }) => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon icon={icon} style={{ width: 24, height: 24 }} />
        <Text
          style={{
            marginLeft: 8,
            ...(isRTL && { marginLeft: 0, marginRight: 8 }),
            fontSize: 16,
            fontWeight: "500",
            color: Colors.text,
          }}
        >
          {title}
        </Text>
      </View>
    );
  };

  return (
    <View
      style={{
        flexGrow: 1,
        backgroundColor: Colors.heading,
        marginBottom: isMobile ? 50 : 100,
      }}
    >
      <View
        style={{
          ...styles.container,
          flexDirection: isMobile ? "column" : "row",
          paddingHorizontal: isMobile ? 16 : 0,
          marginTop: isMobile ? 32 : 48,
        }}
      >
        <View style={styles.leftSection}>
          <Text
            style={{
              ...styles.title,
              fontSize: isMobile ? width * 0.13 : 52,
              lineHeight: isMobile ? width * 0.15 : 64,
            }}
          >
            <Text
              style={{
                backgroundColor: "yellow",
                color: Colors.text,
                paddingHorizontal: 8,
              }}
            >
              {t("landingPageHeading.highlightedTitle")}
            </Text>{" "}
            {t("landingPageHeading.title")}
          </Text>
          <Text
            style={{
              ...styles.subtitle,
              fontSize: isMobile ? 16 : 20,
              lineHeight: isMobile ? 22 : 28,
            }}
          >
            {t("landingPageHeading.subtitle")}
          </Text>

          {!user && (
            <Pressable
              onPress={_onLargeButtonPress}
              style={isMobile ? styles.mobilButton : styles.button}
            >
              {!forceInstall && (
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iIzQyODVGNCIgZD0iTTIyLjU2IDEyLjI1YzAtLjc4LS4wNy0xLjUzLS4yLTIuMjVIMTJ2NC4yNmg1LjkyYy0uMjYgMS4zNy0xLjA0IDIuNTMtMi4yMSAzLjMxdjIuNzdoMy41N2MyLjA4LTEuOTIgMy4yOC00Ljc0IDMuMjgtOC4wOXoiLz48cGF0aCBmaWxsPSIjMzRBODUzIiBkPSJNMTIgMjNjMi45NyAwIDUuNDYtLjk4IDcuMjgtMi42NmwtMy41Ny0yLjc3Yy0uOTguNjYtMi4yMyAxLjA2LTMuNzEgMS4wNi0yLjg2IDAtNS4yOS0xLjkzLTYuMTYtNC41M0gyLjE4djIuODRDMy45OSAyMC41MyA3LjcgMjMgMTIgMjN6Ii8+PHBhdGggZmlsbD0iI0ZCQkMwNSIgZD0iTTUuODQgMTQuMDljLS4yMi0uNjYtLjM1LTEuMzYtLjM1LTIuMDlzLjEzLTEuNDMuMzUtMi4wOVY3LjA3SDIuMThDMS40MyA4LjU1IDEgMTAuMjIgMSAxMnMuNDMgMy40NSAxLjE4IDQuOTNsMi44NS0yLjIyLjgxLS42MnoiLz48cGF0aCBmaWxsPSIjRUE0MzM1IiBkPSJNMTIgNS4zOGMxLjYyIDAgMy4wNi41NiA0LjIxIDEuNjRsMy4xNS0zLjE1QzE3LjQ1IDIuMDkgMTQuOTcgMSAxMiAxIDcuNyAxIDMuOTkgMy40NyAyLjE4IDcuMDdsMy42NiAyLjg0Yy44Ny0yLjYgMy4zLTQuNTMgNi4xNi00LjUzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0xIDFoMjJ2MjJIMXoiLz48L3N2Zz4=" />
              )}
              {forceInstall && (
                <>
                  {!isMobile && (
                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iIzQyODVGNCIgZD0iTTIyLjU2IDEyLjI1YzAtLjc4LS4wNy0xLjUzLS4yLTIuMjVIMTJ2NC4yNmg1LjkyYy0uMjYgMS4zNy0xLjA0IDIuNTMtMi4yMSAzLjMxdjIuNzdoMy41N2MyLjA4LTEuOTIgMy4yOC00Ljc0IDMuMjgtOC4wOXoiLz48cGF0aCBmaWxsPSIjMzRBODUzIiBkPSJNMTIgMjNjMi45NyAwIDUuNDYtLjk4IDcuMjgtMi42NmwtMy41Ny0yLjc3Yy0uOTguNjYtMi4yMyAxLjA2LTMuNzEgMS4wNi0yLjg2IDAtNS4yOS0xLjkzLTYuMTYtNC41M0gyLjE4djIuODRDMy45OSAyMC41MyA3LjcgMjMgMTIgMjN6Ii8+PHBhdGggZmlsbD0iI0ZCQkMwNSIgZD0iTTUuODQgMTQuMDljLS4yMi0uNjYtLjM1LTEuMzYtLjM1LTIuMDlzLjEzLTEuNDMuMzUtMi4wOVY3LjA3SDIuMThDMS40MyA4LjU1IDEgMTAuMjIgMSAxMnMuNDMgMy40NSAxLjE4IDQuOTNsMi44NS0yLjIyLjgxLS42MnoiLz48cGF0aCBmaWxsPSIjRUE0MzM1IiBkPSJNMTIgNS4zOGMxLjYyIDAgMy4wNi41NiA0LjIxIDEuNjRsMy4xNS0zLjE1QzE3LjQ1IDIuMDkgMTQuOTcgMSAxMiAxIDcuNyAxIDMuOTkgMy40NyAyLjE4IDcuMDdsMy42NiAyLjg0Yy44Ny0yLjYgMy4zLTQuNTMgNi4xNi00LjUzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0xIDFoMjJ2MjJIMXoiLz48L3N2Zz4=" />
                  )}
                  {device === "Android" && (
                    <Icon icon="playStore" style={{ width: 24, height: 24 }} />
                  )}
                  {device === "iOS" && (
                    <Icon icon="appStore" style={{ width: 24, height: 24 }} />
                  )}
                </>
              )}
              <Text
                style={{
                  ...styles.buttonText,
                  ...(isRTL && { marginLeft: 0, marginRight: 12 }),
                }}
              >
                {/* {isMobile
                  ? t("landingPageHeading.installApp")
                  : t("landingPageHeading.startTranscribingButton")} */}
                {t("landingPageHeading.startTranscribingButton")}
              </Text>
            </Pressable>
          )}
          {!user && (
            <Text style={{ color: Colors.gray4, marginTop: 8 }}>
              {t("landingPageHeading.freeMinutesText")}{" "}
              <Text
                style={{
                  fontWeight: "bold",
                  lineHeight: 24,
                  backgroundColor: "red",
                  color: Colors.white,
                  paddingVertical: 2,
                  paddingHorizontal: 4,
                }}
              >
                {t("landingPageHeading.freeMinutesAmount")}
              </Text>
              . {t("landingPageHeading.noCreditCardNeeded")}
            </Text>
          )}

          {user && (
            <Pressable
              onPress={_onLargeButtonPress}
              style={{
                ...styles.button,
                minWidth: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={styles.buttonText}>
                {t("landingPageHeading.dashboardButton")}
              </Text>
            </Pressable>
          )}
        </View>

        <View
          style={{
            padding: isMobile ? 0 : 32,
            marginTop: isMobile ? 32 : 0,
            flex: 1,
            alignItems: "center",
          }}
        >
          <Pressable
            onPress={_onLargeButtonPress}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Icon
              icon="upload"
              style={{ width: 24, height: 24, tintColor: Colors.white }}
            />
            <Text
              style={{
                color: Colors.white,
                marginLeft: 8,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
                fontSize: 18,
                fontWeight: "600",
              }}
            >
              {t("landingPageHeading.uploadAudioAndVideoFiles")}
            </Text>
          </Pressable>

          <Pressable onPress={_onLargeButtonPress} style={{ width: "100%" }}>
            <img
              src="/landing.gif"
              style={{
                borderRadius: 8,
                marginTop: 16,
                width: isMobile ? "100%" : "90%",
              }}
            />
          </Pressable>

          <Pressable
            onPress={_onLargeButtonPress}
            style={{
              marginTop: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon icon="emojiStar" style={{ width: 24, height: 24 }} />
            <Text
              style={{
                color: Colors.white,
                marginLeft: 8,
                ...(isRTL && { marginLeft: 0, marginRight: 8 }),
                fontSize: 18,
                fontWeight: "600",
              }}
            >
              {t("landingPageHeading.getTranscriptsInSeconds")}
            </Text>
          </Pressable>
        </View>
      </View>

      <DesktopContainer>
        <View style={{ height: 32 }} />

        <View
          style={{
            height: 64,
            maxWidth: DESKTOP_WIDTH,
            alignSelf: "center",
            width: "100%",
            borderRadius: 12,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: `${Colors.white}90`,
            paddingHorizontal: 24,
          }}
        >
          {renderFeature({
            icon: "emojiTarget",
            title: t("landingPageHeading.accuracy"),
          })}

          {renderFeature({
            icon: "emojiEarth",
            title: t("landingPageHeading.languages"),
          })}

          {renderFeature({
            icon: "emojiExplodingHead",
            title: t("landingPageHeading.tenHoursUploads"),
          })}

          {renderFeature({
            icon: "emojiSpeaker",
            title: t("landingPageHeading.speakerRecognition"),
          })}

          {renderFeature({
            icon: "emojiLock",
            title: t("landingPageHeading.privateAndSecure"),
          })}

          {renderFeature({
            icon: "emojiInfinite",
            title: t("landingPageHeading.unlimittedMinutes"),
          })}
        </View>
      </DesktopContainer>

      <View
        style={{
          marginTop: 12,
          padding: 16,
          borderRadius: 12,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Icon
          icon="openai"
          style={{ width: 30, height: 30, tintColor: Colors.white }}
        />
        <View
          style={{
            marginLeft: 8,
            ...(isRTL && { marginLeft: 0, marginRight: 8 }),
          }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "600", color: Colors.white }}
          >
            {t("landingPageHeading.poweredByWhisper")}
          </Text>
          <Text style={{ fontSize: 13, color: Colors.gray4 }}>
            {t("landingPageHeading.whisperAccuracy")}
          </Text>
        </View>
      </View>
    </View>
  );
};

LandingPageHeading.forceInstall =
  typeof window !== "undefined" &&
  window.location.search.includes("forceInstall=true");

LandingPageHeading.signUpOrDownloadApp = (login: () => void) => {
  if (!LandingPageHeading.forceInstall) {
    return login();
  }

  const device = getMobileOperatingSystem();

  let localeId = window.location.pathname.split("/")[1] || "en";

  if (device === "Android") {
    datadogLogs.logger.info("Install app clicked", { device });
    return window.open(
      `https://play.google.com/store/apps/details?hl=${localeId}&id=com.transcribe.audio.to.text`
    );
  }

  if (device === "iOS") {
    if (localeId === "ar") {
      localeId = "us";
    }
    datadogLogs.logger.info("Install app clicked", { device });
    return window.open(`https://apps.apple.com/${localeId}/app/6705123880`);
  }
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    maxWidth: DESKTOP_WIDTH,
    width: "100%",
    alignSelf: "center",
  },
  leftSection: {
    flex: 1,
    justifyContent: "center",
  },
  title: {
    fontSize: 76,
    fontWeight: "900",
    color: Colors.white,
    lineHeight: 80,
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 28,
    color: Colors.gray4,
    marginBottom: 24,
  },
  button: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderWidth: 2.5,
    borderColor: Colors.white,
    paddingVertical: 20,
    paddingHorizontal: 40,
    borderRadius: 8,
    alignItems: "center",
    alignSelf: "flex-start",
  },
  mobilButton: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderWidth: 2.5,
    borderColor: Colors.white,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    borderRadius: 8,
  },
  buttonText: {
    marginLeft: 12,
    color: Colors.text,
    fontSize: 18,
    fontWeight: "600",
  },
  rightSection: {
    flex: 1,
  },
});

export default LandingPageHeading;
